<template>
  <div>
    <div class="guangao-module">
      <img src="@/assets/banner/righticon.png" class="right-icon" />
      <img src="@/assets/banner/advlogo.png" class="logo" />
      <div class="title">
        <span>{{ $t('ecology.adv.title1') }}</span><span style="color: #04FC65; margin: 0 3px;"> {{ $t('ecology.adv.newVersion') }}</span><br />
        <span>{{ $t('ecology.adv.title2') }}</span>
      </div>
      <div class="date">
        <img src="@/assets/banner/date.png" class="date-icon" />
        <span>{{ $t('ecology.adv.date') }}</span>
      </div>
      <div class="notice-body">
        <br/><br/>
        <div>{{ $t('ecology.adv.advText1') }}</div>
        <!-- <div class="line-dot"></div> -->
        <!-- <div>{{ $t('ecology.adv.advText2') }}</div> -->
      </div>
    </div>
    <div class="bottom-text">Made by MDAO</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    advantageId () {
      return parseInt(this.$route.query.id || 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.guangao-module {
  padding: 20px;
  color: #fff;
  position: relative;
  font-size: 14px;

  // img {
  //   width: 100%;
  // }

  .right-icon {
    position: absolute;
    width: auto;
    height: 373px;
    right: 0;
    top: 0;
  }

  .logo {
    height: 40px;
    width: auto;
  }

  .title {
    color: #fff;
    padding: 10px 0;
    font-weight: bold;
    font-size: 28px;
  }

  .date {
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;

    .date-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .notice-body {
    border-radius: 10px;
    background: rgba(65, 65, 65, 0.70);
    box-shadow: 1px 1px 2px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(10px);
    margin-top: 20px;
    padding: 18px;
    min-height: 380px;

    div {
      line-height: 1.5em;
      letter-spacing: 1px;
      text-align: justify;
    }

    .line-dot {
      border: 1px dotted #04FC65;
      margin: 10px 0;
    }
  }
}

.bottom-text {
  background: #04FC65;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
}
</style>
